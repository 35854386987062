<template>
  <div id="ledger">
    <el-dialog
      :title="ledgerFormTitle"
      width="680px"
      :visible.sync="ledgerDialogVisible"
      :close-on-click-modal="false"
      @close="ledgerDialogClose"
    >
      <el-form
        ref="ledgerFormRef"
        :model="ledgerForm"
        :rules="ledgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="品名" prop="productName">
              <el-input v-model="ledgerForm.productName" placeholder="请输入品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="ledgerForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="ledgerForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="ledgerForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="不合格原因" prop="unqualifiedItems">
              <el-input v-model="ledgerForm.unqualifiedItems" placeholder="请输入不合格原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="ledgerForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理结果" prop="processResult">
              <el-input v-model="ledgerForm.processResult" placeholder="请输入处理结果" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="ledgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="ledgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="ledgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="ledgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="unqualifiedItems" label="不合格原因" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="processResult" label="处理结果" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="登记人" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="ledgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addLedger,
  deleteLedger,
  updateLedger,
  selectLedgerInfo,
  selectLedgerList
} from '@/api/quality/unqualifiedProductLedger'

export default {
  data () {
    return {
      ledgerDialogVisible: false,
      ledgerFormTitle: '',
      ledgerForm: {
        id: '',
        productName: '',
        batchNo: '',
        spec: '',
        quantity: '',
        unqualifiedItems: '',
        reportDate: '',
        processResult: '',
        remarks: ''
      },
      ledgerFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      ledgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectLedgerList(this.searchForm).then(res => {
      this.ledgerPage = res
    })
  },
  methods: {
    ledgerDialogClose () {
      this.$refs.ledgerFormRef.resetFields()
    },
    ledgerFormSubmit () {
      if (this.ledgerFormTitle === '不合格品台账') {
        this.ledgerDialogVisible = false
        return
      }
      this.$refs.ledgerFormRef.validate(async valid => {
        if (valid) {
          if (this.ledgerFormTitle === '新增不合格品台账') {
            this.ledgerForm.id = ''
            await addLedger(this.ledgerForm)
          } else if (this.ledgerFormTitle === '修改不合格品台账') {
            await updateLedger(this.ledgerForm)
          }
          this.ledgerPage = await selectLedgerList(this.searchForm)
          this.ledgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.ledgerFormTitle = '新增不合格品台账'
      this.ledgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteLedger(row.id)
        if (this.ledgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.ledgerPage = await selectLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.ledgerFormTitle = '修改不合格品台账'
      this.ledgerDialogVisible = true
      this.selectLedgerById(row.id)
    },
    handleInfo (index, row) {
      this.ledgerFormTitle = '不合格品台账详情'
      this.ledgerDialogVisible = true
      this.selectLedgerById(row.id)
    },
    selectLedgerById (id) {
      selectLedgerInfo(id).then(res => {
        this.ledgerForm.id = res.id
        this.ledgerForm.productName = res.productName
        this.ledgerForm.batchNo = res.batchNo
        this.ledgerForm.spec = res.spec
        this.ledgerForm.quantity = res.quantity
        this.ledgerForm.unqualifiedItems = res.unqualifiedItems
        this.ledgerForm.reportDate = res.reportDate
        this.ledgerForm.processResult = res.processResult
        this.ledgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
